import styled, { css } from "styled-components";
import DesignTokens from "../../../constants/DesignTokens";

/* Icon 3 */

type HamburgerButtonProps = {
  isOpen: boolean;
};

export const StyledButton = styled.button<HamburgerButtonProps>`
  display: none;
  @media (max-width: 643px) {
    display: initial;
    flex-shrink: 0;
  }

  width: 35px;
  height: 23px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: ${DesignTokens.colors.white};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  background: none;
  color: inherit;
  border: none;
  padding: 0;

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }

  span:nth-child(4) {
    top: 20px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      span:nth-child(1) {
        top: 6px;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        top: 6px;
        width: 0%;
        left: 50%;
      }
    `}
`;
