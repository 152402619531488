import styled from "styled-components";
import { Link } from "react-router-dom";
import DesignTokens from "../../constants/DesignTokens";

export const LinkButton = styled(Link)`
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 18px;
  color: ${DesignTokens.colors.white};
  background-color: ${DesignTokens.colors.darkBlue};
  border: solid 1px ${DesignTokens.colors.white};
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 330px) {
    margin-top: 32px;
  }
`;

export const EmailLinkButton = styled.a`
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 18px;
  color: ${DesignTokens.colors.white};
  background-color: ${DesignTokens.colors.darkBlue};
  border: solid 1px ${DesignTokens.colors.white};
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 330px) {
    margin-top: 32px;
  }
`;
