import { Link } from "@mui/material";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  ServiceContainer,
  PictureContainer,
  Picture,
  DescriptionContainer,
  StyledP,
  StyledH3,
} from "./Service.styles";

interface ServiceProps {
  link: string;
  picture: string;
  title: string;
  description: string | JSX.Element;
  shouldReverse: boolean;
}

const Service = ({
  picture,
  title,
  link,
  description,
  shouldReverse,
}: ServiceProps) => {
  const serviceRef = useRef<HTMLLIElement>(null);
  const { goTo } = useParams();
  useEffect(() => {
    if (link === goTo) {
      serviceRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [link, goTo]);

  return (
    <ServiceContainer ref={serviceRef} id={link} shouldReverse={shouldReverse}>
      <PictureContainer>
        <Picture
          variant="rounded"
          sx={{ width: "100%", height: "450px", maxWidth: "550px" }}
          src={picture}
        />
      </PictureContainer>
      <DescriptionContainer>
        <StyledH3>{title}</StyledH3>
        {typeof description === "string" ? (
          <StyledP>{description}</StyledP>
        ) : (
          description
        )}
        <StyledP>
          Want to learn more?{" "}
          <Link href="mailto:admin@gta-mdt.com">Contact us</Link> or{" "}
          <Link href="/referral">submit a referral</Link>
        </StyledP>
      </DescriptionContainer>
    </ServiceContainer>
  );
};

export default Service;
