import { TeamMember } from "../../constants/team";
import {
  BioContainer,
  UserCredentials,
  StyledH3,
  StyledH4,
  StyledP,
  UserAvatar,
  AvatarContainer,
  StyledCredentials,
} from "./Bio.styles";

type BioProps = TeamMember & {
  shouldReverse: boolean;
};

const Bio = ({
  avatar,
  name,
  title,
  credentials,
  bio,
  shouldReverse,
}: BioProps) => {
  return (
    <BioContainer shouldReverse={shouldReverse}>
      <AvatarContainer>
        <UserAvatar
          alt={`${name} avatar`}
          variant="rounded"
          sx={{ width: "100%", height: "450px", maxWidth: "550px" }}
          src={avatar}
        />
      </AvatarContainer>
      <UserCredentials>
        <StyledH3>
          {name},{" "}
          <StyledCredentials>
            {credentials.toLocaleUpperCase()}
          </StyledCredentials>
        </StyledH3>

        <StyledH4>{title.toLocaleUpperCase()}</StyledH4>
        <StyledP>{bio}</StyledP>
      </UserCredentials>
    </BioContainer>
  );
};

export default Bio;
