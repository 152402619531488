import styled from "styled-components";
import DesignTokens from "../../constants/DesignTokens";

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: ${DesignTokens.colors.white};
  margin: 16px;
  width: 180px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: 0.5s box-shadow;
  :hover {
    box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
  }
`;

export const Icon = styled.img`
  width: 100px;
`;

export const StyledH3 = styled.h3`
  font-size: 16px;
  text-align: center;
  font-weight: 400;
`;
