import styled from "styled-components";

export const AllServicesContainer = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
`;

export const ServicesContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
