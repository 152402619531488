const DesignTokens = {
  colors: {
    lightBlue: "#99d9d9",
    darkBlue: "#04a1b1",
    white: "#fff",
  },
  sizes: {
    fonts: {
      h1: "28px",
      h2: "24px",
    },
  },
};

export default DesignTokens;
