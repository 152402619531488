import team from "../../constants/team";
import Bio from "../Bio";
import { StyledH1 } from "../Home/Home.styles";
import HomeNav from "../HomeNav";
import { TeamContainer, MembersContainer } from "./Team.styles";
import { a, useSpring } from "react-spring";
import Footer from "../Footer";
import DesignTokens from "../../constants/DesignTokens";
import FadeInSection from "../FadeInSection";

const Team = () => {
  const fadeIn = useSpring({
    config: { mass: 50, tension: 2000, friction: 400 },
    opacity: 1,
    from: { opacity: 0.9 },
  });
  return (
    <a.main style={fadeIn}>
      <HomeNav />
      <TeamContainer>
        <StyledH1
          color={DesignTokens.colors.darkBlue}
          style={{ fontSize: "40px" }}
          fontWeight={500}
        >
          Meet Our Team
        </StyledH1>
        <MembersContainer>
          {team.map((teamProps, index) => (
            <FadeInSection key={index}>
              <Bio
                key={teamProps.name}
                {...teamProps}
                shouldReverse={index % 2 !== 0}
              />
            </FadeInSection>
          ))}
        </MembersContainer>
      </TeamContainer>
      <Footer hasReferralButton={true} />
    </a.main>
  );
};

export default Team;
