import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import DesignTokens from "./constants/DesignTokens";
import Team from "./routes/team";
import Home from "./routes/home";
import Referral from "./routes/referral";
import Services from "./routes/services";

const theme = createTheme({
  palette: {
    primary: { main: DesignTokens.colors.darkBlue },
    secondary: { main: DesignTokens.colors.lightBlue },
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/team" element={<Team />} />
          <Route path="/services/:goTo" element={<Services />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
