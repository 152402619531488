import styled from "styled-components";
import DesignTokens from "../../constants/DesignTokens";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { TextField } from "@mui/material";

export const Section = styled(Box)`
  padding: 20px;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

export const FormContainer = styled.div`
  width: 50%;
  min-height: 250px;
  padding: 32px;
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 95%;
  }
`;

interface InputContainerProps {
  withBottomBorder?: boolean;
}
export const InputContainer = styled.div<InputContainerProps>`
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: ${(props) =>
    props.withBottomBorder ? "0.5px solid grey" : ""};
`;

export const CenteredH2 = styled.h2`
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0px;
  font-weight: 500;
`;

export const SubmitReferralButton = styled.button`
  border-radius: 12px;
  padding: 8px 32px;
  font-size: 18px;
  color: ${DesignTokens.colors.white};
  background-color: ${DesignTokens.colors.darkBlue};
  border: none;
  cursor: pointer;
  text-decoration: none;
  align-self: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 0px !important;
`;

export const StyledH3 = styled.h3`
  margin: 0px;
  font-weight: 600;
  text-decoration: underline;
`;

export const SpecifyField = styled(TextField)`
  margin: 8px 0px !important;
`;
