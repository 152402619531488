import { Link } from "@mui/material";
import styled from "styled-components";
import DesignTokens from "../../constants/DesignTokens";

export const StyledFooter = styled.footer`
  padding: 16px;
  background-color: ${DesignTokens.colors.darkBlue};
  color: white;
`;

export const FooterLogo = styled.img`
  max-width: 115px;
  width: auto;
  @media (max-width: 330px) {
    display: none;
  }
`;

export const StyledH2 = styled.h2`
  font-size: 28px;
  font-weight: 300;
  margin: 0;
`;

interface FooterHalfContentType {
  hasRightBorder?: boolean;
}
export const FooterHalfContent = styled.div<FooterHalfContentType>`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-right: ${(props) =>
    props.hasRightBorder
      ? `solid 2px ${DesignTokens.colors.white}`
      : "initial"};

  @media (max-width: 900px) {
    width: 100%;
    border-right: none;
    gap: 8px;
  }
`;

export const Email = styled(Link)`
  font-size: 20px;
  color: white !important;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  text-align: center;
`;

export const SectionContent = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;
