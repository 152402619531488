import { StyledH1 } from "../Home/Home.styles";
import { AllServicesContainer, ServicesContainer } from "./Services.styles";
import HomeNav from "../HomeNav";
import { a, useSpring } from "react-spring";
import Footer from "../Footer";
import DesignTokens from "../../constants/DesignTokens";
import services from "../../constants/services";
import FadeInSection from "../FadeInSection";
import Service from "./Service";
import { useParams } from "react-router-dom";

const Services = () => {
  const fadeIn = useSpring({
    config: { mass: 50, tension: 2000, friction: 400 },
    opacity: 1,
    from: { opacity: 0.9 },
  });

  const { goTo } = useParams();

  return (
    <a.main style={fadeIn}>
      <HomeNav />
      <AllServicesContainer>
        <StyledH1
          color={DesignTokens.colors.darkBlue}
          style={{ fontSize: "40px" }}
          fontWeight={500}
        >
          Our Services
        </StyledH1>
        <ServicesContainer>
          {services.map((serviceProps, index) => {
            const ServiceToRender = (
              <Service
                link={serviceProps.link}
                picture={serviceProps.picture}
                description={serviceProps.description}
                title={serviceProps.title}
                shouldReverse={index % 2 !== 0}
              />
            );

            return !!goTo ? (
              <span key={index}>{ServiceToRender}</span>
            ) : (
              <FadeInSection key={index}>{ServiceToRender}</FadeInSection>
            );
          })}
        </ServicesContainer>
      </AllServicesContainer>
      <Footer hasReferralButton={true} />
    </a.main>
  );
};

export default Services;
