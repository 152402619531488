import maria from "../images/maria.jpg";
import sammie from "../images/sammie.jpg";
import stephanie from "../images/stephanie.jpg";
import brianna from "../images/brianna.jpeg";
import melody from "../images/melody.jpg";
import joachim from "../images/joachim.png";

export type TeamMember = {
  avatar: string;
  name: string;
  credentials: string;
  title: string;
  bio: string;
};

const team: Array<TeamMember> = [
  {
    avatar: sammie,
    name: "Sammie Liu",
    credentials: "PT, DPT",
    title: "Co-Founder, Physiotherapist",
    bio: "Since graduating with her Doctor of Physical Therapy from Boston University, Sammie has worked across the spectrum of healthcare settings to realize her passion for helping clients return to their meaningful activities and achieve their functional potential. She has a special interest in neurological rehabilitation, with a focus on movement disorders and post-stroke recovery. Sammie has a Certified Stroke Rehabilitation Specialist designation and is an LSVT-BIG certified clinician.",
  },
  {
    avatar: stephanie,
    name: "Stephanie Wong",
    credentials: "MHSc., Reg. CASLPO",
    title: "Co-founder, speech-language pathologist",
    bio: "Stephanie obtained her Master of Health Science in Speech-Language Pathology from the University of Toronto, where her involvement in research has resulted in several peer-reviewed publications and conference presentations. Stephanie has years of experience working with clients with movement disorders and other neurological diagnoses, with a special interest in improving speech clarity, cognitive communication, and augmentative and alternative communication needs, to optimize the communication potential of her clients. Stephanie is an LSVT-LOUD certified clinician.",
  },
  {
    avatar: maria,
    name: "Maria Rumeo-Lisi",
    credentials: "RD, CDE",
    title: "Dietitian",
    bio: "Maria has been a dietitian for over 7 years. She completed two undergraduate degrees in Biology at York University and in Food and Nutrition at Toronto Metropolitan University. Her dietetic internship training was completed at the Hospital for Sick Children. Since then, she has worked in a variety of clinical settings including long term care, home care, hospital, and primary care, allowing her to have gained knowledge and experience in neurodegenerative diseases and the essential role that proper nutrition plays in their treatment. She is also a certified diabetes educator (CDE).",
  },
  {
    avatar: brianna,
    name: "Brianna Marshall",
    credentials: "OT Reg. (Ont.), MSC.",
    title: "Occupational Therapist",
    bio: "Brianna is an occupational therapist with experience in neurolodegenerative disease  and cognition. Her past experiences as a family caregiver have made her passionate about working with patients and families to find unique solutions for everyday frustrations that fit an individual’s lifestyle and preferences. Outside of work, Brianna loves to spend her time exploring local restaurants, shows, and hikes.",
  },
  {
    avatar: melody,
    name: "Melody Chow",
    credentials: "B.A. Hons., CDA",
    title: "Communicative Disorders Assistant",
    bio: "Melody holds an undergraduate degree in psycholinguistics from University of Toronto and a postgraduate certificate in communicative disorders assistant from Georgian College. She has worked over 2 years in a movement disorders clinic providing voice and cognitive communication therapy, as well as augmentative alternative communication solutions to clients. Melody takes a creative and novel approach in her therapy, allowing clients' communication abilities to reach their full potential in a fun and engaging manner. Melody is fluent in both English and Cantonese. During her free time she likes making desserts and taking her senior cat out for walks.",
  },
  {
    avatar: joachim,
    name: "Joachim Rodriguez",
    credentials: "HBSc",
    title: "Occupational and Physiotherapist Assistant",
    bio: "Joachim is an Occupational and Physiotherapist Assistant graduating from Humber College. He has experience working with a range of patients in a general rehabilitation setting assisting them achieve meaningful goals and a return to their lifestyle. He has developed an appreciation and passion for neurological/stroke rehabilitation understanding the importance focusing on not only the physical but also cognitive and vocational realms. When not working Joachim is a Toronto Maple Leafs fan (for better or for worse), enjoys exploring the outdoors and playing sports.",
  },
];

export default team;
