import { StyledButton } from "./HamburgerButton.styles";

const HamburgerButton = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => (
  <StyledButton
    aria-label="Toggle Menu Navigation Links"
    onClick={onClick}
    isOpen={isOpen}
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </StyledButton>
);

export default HamburgerButton;
