import React, { SyntheticEvent, useState } from "react";
import emailjs from "@emailjs/browser";

import Footer from "../Footer";
import {
  Section,
  Container,
  FormContainer,
  InputContainer,
  CenteredH2,
  SubmitReferralButton,
  StyledFormHelperText,
  StyledH3,
  SpecifyField,
} from "./Referral.styles";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DesignTokens from "../../constants/DesignTokens";
import HomeNav from "../HomeNav";
import { a, useSpring } from "react-spring";

const Referral = () => {
  const [value, setValue] = useState(null);
  const [additionalInfoState, setAdditionalInfoState] = useState({}) as any;
  const [referralState, setReferralState] = useState({}) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [didError, setDidError] = useState(false);

  const onSpecifyChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    if (target) {
      setAdditionalInfoState((prevState: any) => ({
        ...(!!prevState ? prevState : {}),
        [target.name?.toLowerCase().replace(/\s/g, "")]: target.checked,
      }));
    }
  };

  const onInfoChange = (
    event?: SyntheticEvent,
    manualUpdateObject?: Object
  ) => {
    if (event) {
      const target = event.target as HTMLInputElement;
      if (target) {
        const value =
          target.type === "checkbox" ? target.checked : target.value;
        setReferralState((prevState: any) => ({
          ...(!!prevState ? prevState : {}),
          [target.name]: value,
        }));
      }
    } else if (manualUpdateObject) {
      setReferralState((prevState: any) => ({
        ...(!!prevState ? prevState : {}),
        ...manualUpdateObject,
      }));
    }
  };

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const apiKey = process.env.REACT_APP_EMAILJS_API_KEY;
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    for (const property in referralState) {
      if (!referralState[property]) {
        const updatedReferralState = referralState;
        delete referralState[property];
        setReferralState(updatedReferralState);
      }
    }

    setIsLoading(true);
    if (serviceId) {
      templateId &&
        emailjs.send(serviceId, templateId, referralState, apiKey).then(
          (result) => {
            setIsLoading(false);
            setIsSubmitted(true);
          },
          (error) => {
            setDidError(true);
          }
        );
    } else {
      setDidError(true);
    }
  };

  const fadeIn = useSpring({
    config: { mass: 50, tension: 2000, friction: 400 },
    opacity: 1,
    from: { opacity: 0.9 },
  });

  return (
    <a.main style={fadeIn}>
      <HomeNav />
      <Section id="test" onSubmit={handleSubmit} component="form">
        <Container>
          {isLoading && (
            <FormContainer>
              <CenteredH2>Sending Referral...</CenteredH2>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "64px",
                }}
              >
                <CircularProgress />
              </Box>
            </FormContainer>
          )}
          {didError && (
            <FormContainer>
              <CenteredH2>Error Processing Your Request</CenteredH2>
              <p style={{ textAlign: "center" }}>
                Please refresh and try again, or reach out to us by email if
                your problem persists.
              </p>
            </FormContainer>
          )}
          {isSubmitted && (
            <FormContainer
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CheckCircleOutlineIcon
                  style={{
                    color: DesignTokens.colors.darkBlue,
                    width: "64px",
                    height: "64px",
                  }}
                />
              </Box>
              <CenteredH2>Referral Submitted!</CenteredH2>
              <p style={{ textAlign: "center" }}>
                We will reach out to you to follow up on your referral request.
              </p>
            </FormContainer>
          )}
          {!isSubmitted && !isLoading && !didError && (
            <FormContainer>
              <CenteredH2>Make a Referral</CenteredH2>
              <InputContainer withBottomBorder={true}>
                <StyledH3>Referrer Information</StyledH3>
                <FormControl required={true}>
                  <FormLabel id="referred-by-group-label">
                    Referral Submitted By:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="referred-by-group-label"
                    name="referredBy"
                    onChange={onInfoChange}
                  >
                    <FormControlLabel
                      value="neurologist"
                      control={<Radio required={true} />}
                      label="Neurologist"
                    />
                    <FormControlLabel
                      value="primary physician"
                      control={<Radio required={true} />}
                      label="Primary Physician"
                    />
                    <FormControlLabel
                      value="self-referral"
                      control={<Radio required={true} />}
                      label="Self-Referral"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio required={true} />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>

                <TextField
                  required={true}
                  variant="outlined"
                  label="Referrer's Name"
                  name="referrersName"
                  onChange={onInfoChange}
                />

                <FormControl>
                  <TextField
                    required={false}
                    variant="outlined"
                    label="Referrer's Contact Phone Number"
                    aria-describedby="referrer-phone-helper-text"
                    name="referrersPhone"
                    onChange={onInfoChange}
                  />
                  <StyledFormHelperText id="referrer-phone-helper-text">
                    If self-referred, please enter 'N/A'.
                  </StyledFormHelperText>
                </FormControl>

                <FormControl>
                  <TextField
                    required={true}
                    variant="outlined"
                    label="Referrer's Contact Email"
                    aria-describedby="referrer-email-helper-text"
                    name="referrersEmail"
                    onChange={onInfoChange}
                  />
                  <StyledFormHelperText id="referrer-email-helper-text">
                    If self-referred, please enter 'N/A'.
                  </StyledFormHelperText>
                </FormControl>
              </InputContainer>

              <InputContainer withBottomBorder={true}>
                <StyledH3>Patient Information</StyledH3>
                <TextField
                  required={true}
                  variant="outlined"
                  label="Patient Name"
                  name="patientName"
                  onChange={onInfoChange}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Birth"
                    value={value}
                    onChange={(newValue: any) => {
                      setValue(newValue);
                      const dob = new Date(newValue);

                      onInfoChange(undefined, {
                        dateOfBirth: `0${
                          dob.getMonth() + 1
                        }/0${dob.getDay()}/${dob.getFullYear()}`,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        required={true}
                        {...params}
                        name="dateOfBirth"
                        onChange={onInfoChange}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  required={true}
                  variant="outlined"
                  label="Patient's Contact Phone Number"
                  name="patientsPhone"
                  onChange={onInfoChange}
                />
                <TextField
                  required={true}
                  variant="outlined"
                  type="email"
                  label="Patient's Contact Email"
                  name="patientsEmail"
                  onChange={onInfoChange}
                />
                <TextField
                  required={true}
                  variant="outlined"
                  type="text"
                  label="Movement Disorder Diagnosis"
                  name="movementDisorderDiagnosis"
                  onChange={onInfoChange}
                />
                <TextField
                  required={true}
                  variant="outlined"
                  type="text"
                  multiline={true}
                  minRows={3}
                  maxRows={5}
                  label="Relevant Medical History"
                  name="relevantMedicalHistory"
                  onChange={onInfoChange}
                />
              </InputContainer>

              <InputContainer withBottomBorder={true}>
                <StyledH3>Services</StyledH3>
                <FormControl>
                  <FormLabel id="physio-group-label">Physiotherapy</FormLabel>
                  <StyledFormHelperText>
                    Please check all that apply
                  </StyledFormHelperText>
                  <FormGroup
                    aria-labelledby="physio-group-label"
                    onChange={onInfoChange}
                  >
                    <FormControlLabel
                      value="falls"
                      label="Falls"
                      control={<Checkbox name="Falls" />}
                    />
                    <FormControlLabel
                      value="posture"
                      label="Posture"
                      control={<Checkbox name="Posture" />}
                    />
                    <FormControlLabel
                      value="exercise program"
                      label="Exercise Program"
                      control={<Checkbox name="Exercise Program" />}
                    />
                    <FormControlLabel
                      value="pain"
                      label="Pain"
                      onChange={onSpecifyChange}
                      control={<Checkbox name="Pain" />}
                    />
                    {!!additionalInfoState?.pain && (
                      <SpecifyField
                        required={true}
                        name="painSpecified"
                        variant="filled"
                        label="Please Specify"
                      />
                    )}
                    <FormControlLabel
                      value="gait and balance impairments"
                      label="Gait and Balance Impairments"
                      onChange={onSpecifyChange}
                      control={<Checkbox name="Gait and Balance Impairments" />}
                    />
                    {!!additionalInfoState?.gaitandbalanceimpairments && (
                      <SpecifyField
                        name="gaitSpecified"
                        required={true}
                        variant="filled"
                        label="Please Specify"
                      />
                    )}
                  </FormGroup>
                </FormControl>

                <FormControl>
                  <FormLabel id="speech-group-label">
                    Speech and Language Therapy
                  </FormLabel>
                  <StyledFormHelperText>
                    Please check all that apply
                  </StyledFormHelperText>
                  <FormGroup
                    aria-labelledby="speech-group-label"
                    onChange={onInfoChange}
                  >
                    <FormControlLabel
                      value="speech clarity"
                      label="Speech Clarity"
                      control={<Checkbox name="Speech Clarity" />}
                    />
                    <FormControlLabel
                      value="voice volume"
                      label="Voice Volume"
                      control={<Checkbox name="Voice Volume" />}
                    />
                    <FormControlLabel
                      value="cognitive communication"
                      label="Cognitive Communication (i.e. word-finding, verbal memory)"
                      control={<Checkbox name="Cognitive Communication" />}
                    />
                    <FormControlLabel
                      value="swallowing"
                      label="Swallowing"
                      control={<Checkbox name="Swallowing" />}
                    />
                  </FormGroup>
                </FormControl>

                <FormControl>
                  <FormLabel id="dietetics-group-label">Dietetics</FormLabel>
                  <StyledFormHelperText>
                    Please check all that apply
                  </StyledFormHelperText>
                  <FormGroup
                    aria-labelledby="dietetics-group-label"
                    onChange={onInfoChange}
                  >
                    <FormControlLabel
                      value="weight management"
                      label="Weight Management"
                      control={<Checkbox name="Weight Management" />}
                    />

                    <FormControlLabel
                      value="medication/protein interaction"
                      label="Medication/Protein Interaction"
                      control={
                        <Checkbox name="Medication/Protein Interaction" />
                      }
                    />

                    <FormControlLabel
                      value="constipation"
                      label="Constipation"
                      control={<Checkbox name="Constipation" />}
                    />

                    <FormControlLabel
                      value="blood pressure management"
                      label="Blood Pressure Management (i.e. orthostatic hypotension)"
                      control={<Checkbox name="Blood Pressure Management" />}
                    />

                    <FormControlLabel
                      value="other symptom management"
                      label="Other Symptom Management (i.e. heartburn, nausea):"
                      onChange={onSpecifyChange}
                      control={<Checkbox name="Other Symptom Management" />}
                    />
                    {!!additionalInfoState?.othersymptommanagement && (
                      <SpecifyField
                        name="otherSymptomManagementSpecified"
                        required={true}
                        variant="filled"
                        label="Please Specify"
                      />
                    )}

                    <FormControlLabel
                      value="general healthy eating education"
                      label="General Healthy Eating Education"
                      control={
                        <Checkbox name="General Healthy Eating Education" />
                      }
                    />
                  </FormGroup>
                </FormControl>

                <FormControl>
                  <FormLabel id="occupational-group-label">
                    Occupational Therapy
                  </FormLabel>
                  <StyledFormHelperText>
                    Please check all that apply
                  </StyledFormHelperText>
                  <FormGroup
                    aria-labelledby="occupational-group-label"
                    onChange={onInfoChange}
                  >
                    <FormControlLabel
                      value="cognitive strategies"
                      label="Cognitive Strategies"
                      onChange={onSpecifyChange}
                      control={<Checkbox name="Cognitive Strategies" />}
                    />
                    {!!additionalInfoState?.cognitivestrategies && (
                      <SpecifyField
                        name="cognitiveStrategiesSpecified"
                        required={true}
                        variant="filled"
                        label="Please Specify"
                      />
                    )}

                    <FormControlLabel
                      value="adaptive strategies for fine motor difficulties"
                      label="Adaptive strategies for fine motor difficulties (i.e. eating, dressing, writing):"
                      onChange={onSpecifyChange}
                      control={
                        <Checkbox name="Adaptive strategies for fine motor difficulties" />
                      }
                    />
                    {!!additionalInfoState?.adaptivestrategiesforfinemotordifficulties && (
                      <SpecifyField
                        name="adaptiveStrategiesSpecified"
                        required={true}
                        variant="filled"
                        label="Please Specify"
                      />
                    )}
                  </FormGroup>
                </FormControl>

                <TextField
                  variant="outlined"
                  type="text"
                  multiline={true}
                  minRows={3}
                  maxRows={5}
                  label="Additional Information"
                  name="additionalInformation"
                  onChange={onInfoChange}
                />
              </InputContainer>
              <InputContainer>
                <SubmitReferralButton type="submit">
                  Submit
                </SubmitReferralButton>
              </InputContainer>
            </FormContainer>
          )}
        </Container>
      </Section>
      <Footer hasReferralButton={false} />
    </a.main>
  );
};

export default Referral;
