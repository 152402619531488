import { useState } from "react";
import {
  NavContainer,
  StyledHomeLink,
  StyledLink,
  LinksContainer,
  HamburgerLinksContainer,
  StyledMailLink,
  NavTopContainer,
} from "./HomeNav.styles";

import { useLocation } from "react-router";
import HamburgerButton from "./HamburgerButton";

const HomeNav = () => {
  const location = useLocation().pathname;
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleOpenHamburgerMenu = () =>
    setIsHamburgerMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);

  const isTeamPage = location === "/team";
  const isHomePage = location === "/";
  const isReferralPage = location === "/referral";
  const isServicesPage = location === "/services";

  return (
    <NavContainer>
      <NavTopContainer>
        <StyledHomeLink to="/">GTA Movement Disorders Team</StyledHomeLink>
        <HamburgerButton
          isOpen={isHamburgerMenuOpen}
          onClick={toggleOpenHamburgerMenu}
        />
        <LinksContainer>
          {!isHomePage && <StyledLink to="/">Home</StyledLink>}
          {!isServicesPage && <StyledLink to="/services">Services</StyledLink>}
          {!isTeamPage && <StyledLink to="/team">Our Team</StyledLink>}
          {!isReferralPage && (
            <StyledLink to="/referral">Make a Referral</StyledLink>
          )}
          <StyledMailLink href="mailto:admin@gta-mdt.com">
            Contact Us
          </StyledMailLink>
        </LinksContainer>
      </NavTopContainer>
      {isHamburgerMenuOpen && (
        <HamburgerLinksContainer>
          {!isHomePage && <StyledLink to="/">Home</StyledLink>}
          {!isServicesPage && <StyledLink to="/services">Services</StyledLink>}
          {!isTeamPage && <StyledLink to="/team">Our Team</StyledLink>}
          {!isReferralPage && (
            <StyledLink to="/referral">Make a Referral</StyledLink>
          )}
          <StyledMailLink href="mailto:admin@gta-mdt.com">
            Contact Us
          </StyledMailLink>
        </HamburgerLinksContainer>
      )}
    </NavContainer>
  );
};

export default HomeNav;
