import { Avatar } from "@mui/material";
import styled from "styled-components";
import DesignTokens from "../../../constants/DesignTokens";

export const ServiceContainer = styled.li<{ shouldReverse: boolean }>`
  display: flex;
  align-items: center;
  gap: 48px;
  padding: 18px 48px;
  flex-direction: ${(props) => (props.shouldReverse ? "row-reverse" : "row")};
  margin-bottom: 32px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const PictureContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const StyledH3 = styled.h3`
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 32px;
  color: ${DesignTokens.colors.darkBlue};
`;

export const Picture = styled(Avatar)`
  box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
`;

export const DescriptionContainer = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const StyledP = styled.span`
  font-size: 20px;
  margin-bottom: 0px;
`;

export const StyledSpan = styled.span`
  font-size: 20px;
  margin-bottom: 0px;
`;
