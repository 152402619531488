import { Link } from "react-router-dom";
import { Container, Icon, StyledH3 } from "./Service.styles";

interface ServiceProps {
  icon: string;
  title: string;
  link: string;
}

const Service = ({ icon, title, link }: ServiceProps) => {
  return (
    <Container>
      <Link
        style={{ textDecoration: "none", color: "black", padding: "16px 32px" }}
        to={`/services/${link}`}
      >
        <Icon src={icon} />
        <StyledH3 style={{ marginTop: "auto" }}>{title}</StyledH3>
      </Link>
    </Container>
  );
};

export default Service;
