import dietetics from "../images/dietetics.png";
import occupational from "../images/occupational.png";
import physio from "../images/physio.png";
import speech from "../images/speech.png";
import dietetics_lg from "../images/dietetics_lg.png";
import occupational_lg from "../images/occupational_lg.png";
import physio_lg from "../images/physio_lg.png";
import speech_lg from "../images/speech_lg.png";
import { StyledSpan } from "../components/Services/Service/Service.styles";

const services = [
  {
    title: "Occupational Therapy",
    link: "occupational-therapy",
    icon: occupational,
    picture: occupational_lg,
    description: (
      <StyledSpan>
        Occupational therapy can help you find practical solutions for everyday
        frustrations and eliminate barriers to doing your daily activities. Some
        concerns we can help to address include:
        <ul>
          <li>Cognition (memory, problem solving)</li>
          <li>Fine motor skills (eating, dressing, writing)</li>
          <li>Energy conservation (pacing, fatigue management)</li>
          <li>
            Activity modifications for your everyday tasks and responsibilities
          </li>
          <li>
            Home safety and accessibility{" "}
            <em>*may not be available in all areas</em>
          </li>
        </ul>
      </StyledSpan>
    ),
  },
  {
    title: "Dietetics",
    link: "dietetics",
    icon: dietetics,
    picture: dietetics_lg,
    description: (
      <StyledSpan>
        A registered dietitian can provide expert advice in optimizing your
        nutritional status and managing side effects of medication as well as
        other nutrition-related symptoms of movement disorders. These include,
        but are not limited to:
        <ul>
          <li>Weight management (weight loss or gain)</li>
          <li>L-dopa and protein interaction</li>
          <li>Constipation</li>
          <li>Orthostatic hypotension or low blood pressure</li>
          <li>Heartburn</li>
          <li>Nausea</li>
        </ul>
      </StyledSpan>
    ),
  },
  {
    title: "Speech and Language Therapy",
    link: "speech-and-language-therapy",
    icon: speech,
    picture: speech_lg,
    description: (
      <StyledSpan>
        Optimize your communication success with strategies to improve voice
        loudness, speech clarity, and cognitive communication. Some areas speech
        therapy can help with are:
        <ul>
          <li>Voice volume</li>
          <li>Speech clarity</li>
          <li>Word finding difficulties</li>
          <li>Verbal memory</li>
          <li>Swallowing difficulties</li>
        </ul>
      </StyledSpan>
    ),
  },
  {
    title: "Physiotherapy",
    link: "physiotherapy",
    icon: physio,
    picture: physio_lg,
    description: (
      <StyledSpan>
        Physiotherapy aims to optimize your mobility so that you can move more
        confidently and get back to the activities you love. This may mean:
        <ul>
          <li>
            Development of an exercise program to better your strength,
            flexibility, endurance, and posture
          </li>
          <li>
            Education on strategies to improve the quality of your walking
            (shuffling, freezing of gait, etc.)
          </li>
          <li>Balance training to reduce the risk of falls</li>
          <li>Pain management</li>
        </ul>
      </StyledSpan>
    ),
  },
];

export default services;
