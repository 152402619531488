import {
  SectionOne,
  SectionImage,
  SectionTwo,
  SectionContent,
  MainContent,
  SectionTwoLeftContent,
  SectionTwoRightContent,
  CTAText,
  StyledH1,
  StyledH2,
  ServicesContainer,
  SectionThree,
} from "./Home.styles";
import hands from "../../images/hands.png";
import DesignTokens from "../../constants/DesignTokens";
import services from "../../constants/services";
import Service from "../Service";
import Footer from "../Footer";
import HomeNav from "../HomeNav";
import { a, useSpring } from "react-spring";
import { useMediaQuery } from "@mui/material";

import { EmailLinkButton } from "../LinkButton";

const Home = () => {
  const fadeIn = useSpring({
    config: { mass: 50, tension: 2000, friction: 400 },
    opacity: 1,
    from: { opacity: 0.9 },
  });

  const shrinkText = useMediaQuery("(max-width:1150px)");
  const changeTextColor = useMediaQuery("(max-width:643px)");
  return (
    <a.main style={fadeIn}>
      <HomeNav />
      <SectionOne>
        <SectionContent>
          <MainContent>
            <StyledH1
              fontWeight={600}
              fontSize={shrinkText ? 45 : 55}
              color={
                changeTextColor
                  ? DesignTokens.colors.darkBlue
                  : DesignTokens.colors.white
              }
            >
              Patient-Centred,
              <br />
              Team-Based Care.
            </StyledH1>
            <StyledH2
              color={
                changeTextColor
                  ? DesignTokens.colors.darkBlue
                  : DesignTokens.colors.white
              }
              fontWeight={500}
              style={{ marginTop: "32px", textAlign: "center" }}
            >
              Our team is currently providing both virtual and in-home services
              in the Greater Toronto Area. Contact us for a free phone
              consultation today.
            </StyledH2>
            <EmailLinkButton
              style={{ marginTop: "32px" }}
              href="mailto:admin@gta-mdt.com"
            >
              Book now
            </EmailLinkButton>
          </MainContent>
        </SectionContent>
      </SectionOne>
      <SectionTwo>
        <SectionTwoLeftContent>
          <SectionImage
            src={hands}
            alt="People of different ages and nationalities fold their hands on each other, symbolizing their unity and support"
          />
        </SectionTwoLeftContent>
        <SectionTwoRightContent>
          <CTAText
            noMargin={true}
            color="white"
            fontSize={shrinkText ? 25 : 30}
            fontWeight={600}
          >
            Our vision for the Movement Disorders Team is to be your one stop
            for multi-disciplinary movement disorders care.
          </CTAText>
          <CTAText
            color="white"
            fontSize={shrinkText ? 15 : 20}
            fontWeight={500}
          >
            We understand that the impact of a neurodegenerative disorder is
            ever changing.
          </CTAText>
          <CTAText
            noMargin={true}
            color="white"
            fontSize={shrinkText ? 15 : 20}
            fontWeight={500}
          >
            Our team aims to take a holistic approach to work together with our
            clients in order to support them across the continuum of their
            diagnoses.
          </CTAText>
        </SectionTwoRightContent>
      </SectionTwo>

      <SectionThree>
        <StyledH1
          style={{ fontSize: "40px" }}
          color={DesignTokens.colors.darkBlue}
          fontWeight={500}
        >
          Our Services
        </StyledH1>
        <CTAText color="grey" fontSize={shrinkText ? 15 : 20} fontWeight={500}>
          Our clinicians are experienced in care of patients with Parkinson's
          Disease, Parkinson's Plus disorders, ataxia, and other movement
          disorders.
        </CTAText>
        <ServicesContainer>
          {services.map((serviceProps) => (
            <Service key={serviceProps.title} {...serviceProps} />
          ))}
        </ServicesContainer>
        <CTAText color="grey" fontSize={shrinkText ? 15 : 20} fontWeight={500}>
          Click on a service to learn more.
        </CTAText>
      </SectionThree>
      <Footer hasReferralButton={true} />
    </a.main>
  );
};

export default Home;
