import styled from "styled-components";
import { Avatar } from "@mui/material";
import DesignTokens from "../../constants/DesignTokens";

export const BioContainer = styled.li<{ shouldReverse: boolean }>`
  display: flex;
  align-items: center;
  gap: 96px;
  padding: 18px;
  flex-direction: ${(props) => (props.shouldReverse ? "row-reverse" : "row")};
  margin-bottom: 64px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const UserAvatar = styled(Avatar)`
  box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
`;

export const UserCredentials = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const StyledH3 = styled.h3`
  font-weight: 500;
  margin-bottom: 12px;
  font-size: 32px;
  color: ${DesignTokens.colors.darkBlue};
`;

export const StyledH4 = styled.h4`
  font-weight: 300;
  margin: 2px;
  margin-bottom: 8px;
  font-size: 24px;
`;

export const StyledCredentials = styled.span`
  font-weight: 300;
  margin: 2px;
  margin-bottom: 8px;
`;

export const StyledP = styled.p`
  font-size: 20px;
  margin-bottom: 0px;
`;
