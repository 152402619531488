import styled from "styled-components";
import DesignTokens from "../../constants/DesignTokens";
import banner from "../../images/banner.jpeg";

export const SectionOne = styled.div`
  background: url(${banner}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 48px 0px;
  box-shadow: 0 1px 2px -1px rgb(0 0 0 / 25%);
  position: relative;

  @media (max-width: 643px) {
    background: none;
  }
`;

export const SectionTwo = styled.div`
  padding: 64px;
  display: flex;
  gap: 32px;
  background-color: ${DesignTokens.colors.darkBlue};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SectionImage = styled.img`
  height: 300px;
  border-radius: 8px;
  @media (max-width: 1350px) {
    height: 250px;
  }
  @media (max-width: 1100px) {
    height: 200px;
  }
  @media (max-width: 900px) {
    max-width: 400px;
    height: auto;
    width: 100%;
  }
`;

export const SectionThree = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

export const ServicesContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px;
  @media (max-width: 1047px) {
    justify-content: center;
  }
  @media (max-width: 555px) {
    justify-content: center;
  }
`;

interface StyledCTAType {
  color: string;
  fontWeight: number;
  fontSize: number;
  noMargin?: boolean;
}
export const CTAText = styled.p<StyledCTAType>`
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  margin: ${(props) => (props.noMargin ? "0px" : "")};
`;

interface StyledH1Type {
  fontWeight: number;
  color?: string;
  fontSize?: number;
}

export const StyledH1 = styled.h1<StyledH1Type>`
  color: ${(props) => props.color || "initial"};
  font-size: ${(props) => `${props.fontSize || DesignTokens.sizes.fonts.h1}px`};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;
  margin: 0px;
`;

export const StyledH2 = styled.h2<StyledH1Type>`
  color: ${(props) => props.color || "initial"};
  font-size: ${(props) => `${props.fontSize || DesignTokens.sizes.fonts.h2}px`};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;
  margin: 0px;
`;

export const MainContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  @media (max-width: 900px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const SectionTwoLeftContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const SectionTwoRightContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
    text-align: center;
  }
`;

export const SectionContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 32px;
  flex-direction: column;
`;

export const StyledP = styled.p`
  font-size: 20px;
  margin-bottom: 0px;
`;
