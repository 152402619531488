import {
  Email,
  FooterHalfContent,
  FooterLogo,
  StyledFooter,
  CenteredContainer,
  SectionContent,
  StyledH2,
  ContactContainer,
} from "./Footer.styles";
import logo from "../../images/logo.png";
import { LinkButton } from "../LinkButton";

interface FooterProps {
  hasReferralButton: boolean;
}

const Footer = ({ hasReferralButton }: FooterProps) => {
  return (
    <StyledFooter>
      <CenteredContainer>
        <StyledH2 style={{ textAlign: "center" }}>
          Our team is currently providing both virtual and in-home services.
        </StyledH2>
        <p>Please contact us for a free 15-minute consultation</p>
      </CenteredContainer>

      <SectionContent>
        <FooterHalfContent hasRightBorder={true}>
          <FooterLogo
            role="logo"
            src={logo}
            alt="gta movement disorders logo"
          />
        </FooterHalfContent>
        <FooterHalfContent>
          {hasReferralButton && (
            <LinkButton to="/referral">Make a Referral</LinkButton>
          )}
          <ContactContainer>
            <StyledH2 style={{ marginTop: "0px" }}>Get in touch: </StyledH2>
            <Email href="mailto:admin@gta-mdt.com">admin@gta-mdt.com</Email>
          </ContactContainer>
        </FooterHalfContent>
      </SectionContent>
    </StyledFooter>
  );
};

export default Footer;
