import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTrail, a } from "react-spring";
import React from "react";
import DesignTokens from "../../constants/DesignTokens";

export const NavContainer = styled.nav`
  @media (max-width: 643px) {
    // sticky styles
    position: fixed;
    z-index: 10;
    width: 100%;
    margin-top: -78px;
  }

  @media (max-width: 543px) {
    margin-top: -60px;
  }

  @media (max-width: 430px) {
    margin-top: -57.5px;
  }

  flex-wrap: wrap;
  background-color: ${DesignTokens.colors.darkBlue};
  box-shadow: 0 2px 2px -1px rgb(0 0 0 / 25%);
  z-index: 2;
  position: relative;
`;

export const NavTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;

  @media (max-width: 1220px) {
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 643px) {
    justify-content: space-between;
    padding: 8px 16px;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const NoStyleLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export const StyledLink = styled(NoStyleLink)`
  font-size: 20px;
  padding: 20px;
  box-shadow: inset 0 0 0 0 ${DesignTokens.colors.lightBlue};
  margin: 0 -0.25rem;
  padding: 0 0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: inset 180px 0 0 0 ${DesignTokens.colors.lightBlue};
    color: white;
  }
`;

export const StyledHomeLink = styled(NoStyleLink)`
  font-weight: 500;
  font-size: 26px;
  margin-left: 32px;
  padding: 16px;
  text-align: center;
  @media (max-width: 1220px) {
    padding-bottom: 0px;
    margin-left: 0px;
  }
  @media (max-width: 643px) {
    padding: 16px;
  }
  @media (max-width: 543px) {
    font-size: 24px;
    padding: 8px;
  }
  @media (max-width: 430px) {
    font-size: 22px;
  }
`;

export const StyledMailLink = styled.a`
  font-size: 20px;
  padding: 20px;
  text-decoration: none;
  color: white;
  box-shadow: inset 0 0 0 0 ${DesignTokens.colors.lightBlue};
  margin: 0 -0.25rem;
  padding: 0 0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: inset 180px 0 0 0 ${DesignTokens.colors.lightBlue};
    color: white;
  }
`;

const StyledLinksContainer = styled.div`
  margin-right: 32px;
  display: flex;
  @media (max-width: 1220px) {
    margin-right: 0px;
  }
  @media (max-width: 643px) {
    display: none;
  }
`;

const StyledHamburgerLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  justify-content: center;
  @media (max-width: 643px) {
    display: initial;
  }
`;

export const HamburgerLinksContainer: React.FC = ({ children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 75, tension: 1500, friction: 150 },
    opacity: 1,
    delay: 100,
    from: { opacity: 0 },
  });
  return (
    <StyledHamburgerLinksContainer>
      {trail.map(({ ...style }, index) => (
        <a.div
          key={index}
          style={{ padding: "16px", textAlign: "center", ...style }}
        >
          {items[index]}
        </a.div>
      ))}
    </StyledHamburgerLinksContainer>
  );
};

export const LinksContainer: React.FC = ({ children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 75, tension: 1500, friction: 150 },
    opacity: 1,
    delay: 100,
    from: { opacity: 0 },
  });
  return (
    <StyledLinksContainer>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} style={{ padding: "16px", ...style }}>
          {items[index]}
        </a.div>
      ))}
    </StyledLinksContainer>
  );
};
