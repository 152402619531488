import React, { ReactNode } from "react";

function FadeInSection(props: { children: ReactNode }) {
  const [hasBeenVisible, setHasBeenVisible] = React.useState(false);
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isVisible) {
      setHasBeenVisible(true);
    }
  }, [isVisible]);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setVisible(entry.isIntersecting);
      });
    });
    const observedElement = domRef.current;

    if (observedElement !== null) {
      observer.observe(observedElement);
    }

    return () => {
      observedElement && observer.unobserve(observedElement);
    };
  }, []);

  return (
    <div
      className={`fade-in-section ${
        isVisible || hasBeenVisible ? "is-visible" : ""
      }`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default FadeInSection;
